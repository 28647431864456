import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-corona-california.png'
import image0 from "../../images/cities/scale-model-of-tom's-farms-in-corona-california.png"
import image1 from "../../images/cities/scale-model-of-castle-park-in-corona-california.png"
import image2 from "../../images/cities/scale-model-of-city-park-in-corona-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Corona'
            state='California'
            image={image}
            lat='33.8752935'
            lon='-117.56643839999998'
            attractions={ [{"name": "Tom's Farms", "vicinity": "23900 Temescal Canyon Rd, Temescal Valley", "types": ["furniture_store", "home_goods_store", "food", "store", "point_of_interest", "establishment"], "lat": 33.772918, "lng": -117.48673100000002}, {"name": "Castle Park", "vicinity": "3500 Polk St, Riverside", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 33.9029205, "lng": -117.46742130000001}, {"name": "City Park", "vicinity": "930 E 6th St, Corona", "types": ["park", "point_of_interest", "establishment"], "lat": 33.87537630000001, "lng": -117.55472099999997}] }
            attractionImages={ {"Tom's Farms":image0,"Castle Park":image1,"City Park":image2,} }
       />);
  }
}